import React, { useCallback, useMemo, useState, useGlobal } from "reactn";
import { useInvokeConverseAiRequestQuery } from "./invokeConverseAiRequestGraphQL";
import Loading from "../../app/loading";
import Container from "../../../styledComponents/container";
import { C1, CA, Row } from "../../../styledComponents/grid";
import { Button } from "../../../styledComponents/form";
const defaultMessage = {
  role: "user",
  content: [{ text: "" }],
};
const models = [
  {
    label: "Anthropic Claude V3 Haiku",
    value: "anthropic.claude-3-haiku-20240307-v1:0",
  },
  {
    label: "Anthropic Claude V3 Sonnet",
    value: "anthropic.claude-3-sonnet-20240229-v1:0",
  },

  {
    label: "Anthropic Claude V3 Opus",
    value: "anthropic.claude-3-opus-20240229-v1:0",
  },
  {
    label: "Anthropic Claude V3.5 Sonnet",
    value: "anthropic.claude-3-5-sonnet-20240620-v1:0",
  },
  {
    label: "Anthropic Claude V3.7 Sonnet",
    value: "us.anthropic.claude-3-7-sonnet-20250219-v1:0",
  },
];
function Ai() {
  const [modelId, setModelId] = useGlobal("aiModelId");
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState({ ...defaultMessage });
  const [invokeConverseAiRequest, { data }] = useInvokeConverseAiRequestQuery();
  const [loading, setLoading] = useState(false);
  useMemo(() => {
    if (data?.errors?.length) {
      setLoading(false);
      alert(data.errors[0]);
    }
  }, [data]);
  useMemo(() => {
    if (data) {
      const resp = data.invokeConverseAiRequest;
      setLoading(false);
      if (resp) {
        setMessages((messages) => [
          ...messages,
          { content: [{ text: resp.message }], role: resp.role },
        ]);
      }
    }
  }, [data]);
  const sendAiQuestion = useCallback(async () => {
    if (!currentMessage.content[0].text) return;

    setLoading(true);
    invokeConverseAiRequest({
      variables: {
        data: {
          messages: [...messages, currentMessage],
          modelId,
        },
      },
    });
    setCurrentMessage({ ...defaultMessage });
    setMessages([...messages, currentMessage]);
  }, [currentMessage, messages, invokeConverseAiRequest, modelId]);
  if (loading) {
  }
  return (
    <Container>
      <Row>
        <C1>
          <h3>AI</h3>
        </C1>
        <CA>
          <label>Ai Model:</label>
          <br />
          <select
            onChange={(e) => setModelId(e.target.value)}
            defaultValue={modelId}
            style={{ height: "32px", fontSize: "16px" }}
          >
            {models.map((model) => {
              return (
                <option key={model.value} value={model.value}>
                  {model.label}
                </option>
              );
            })}
          </select>
        </CA>
      </Row>
      <Row></Row>

      <div style={{ marginTop: 8 }}>
        <div
          style={{
            border: "1px solid grey",
            height: "500px",
            maxHeight: "500px",
            overflowY: "scroll",
            backgroundColor: "#fefefe",
          }}
        >
          {messages.map((message, i) => (
            <div
              key={i}
              style={{
                margin: 8,
                padding: 8,
                display: "flex",
                backgroundColor: i % 2 ? "#f3f3f3" : undefined,
              }}
            >
              <Row>
                <CA style={{ paddingRight: 2 }}>
                  <b>{message.role === "user" ? "You: " : "AI: "}</b>
                </CA>
                <CA style={{ whiteSpace: "pre-wrap", width: "1000px" }}>
                  {message?.content[0].text}
                </CA>
              </Row>
            </div>
          ))}
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <textarea
              value={currentMessage.content[0].text}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendAiQuestion();
                }
              }}
              onChange={(e) =>
                setCurrentMessage({
                  content: [{ text: e.target.value }],
                  role: "user",
                })
              }
              style={{ width: "99.5%", height: 50, marginTop: 8 }}
            />
            <Row>
              <C1>
                <Button
                  style={{ backgroundColor: "#DA4453" }}
                  onClick={() => setMessages([])}
                >
                  Clear
                </Button>
              </C1>
              <CA>
                <Button onClick={sendAiQuestion}>Send</Button>
              </CA>
            </Row>
          </>
        )}
      </div>
    </Container>
  );
}

export default Ai;
