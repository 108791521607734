import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const CLINIC_PET_PARENTS = gql`
  query getPetParents(
    $where: ClinicPetParentWhereInput!
    $skip: Int!
    $take: Int!
    $orderBy: [ClinicPetParentOrderByWithRelationInput!]
  ) {
    clinicPetParents: findManyClinicPetParent(
      where: $where
      skip: $skip
      take: $take
      orderBy: $orderBy
    ) {
      id
      isActive
      isDeleted
      firstName
      lastName
      email
      isPrivacyProtected
      creationSource
      phoneNumbers {
        number
      }
      clinicId
      channelMembers {
        channelId
      }
      clinic {
        id
        name
        firebaseId
      }
      pets {
        id
        name
        species
        isDeceased
      }
    }
    clinicPetParentsCount: findManyClinicPetParentCount(where: $where)
  }
`;

const UPDATE_CLINIC_PET_PARENT = gql`
  mutation updateClinicPetParent(
    $where: ClinicPetParentWhereUniqueInput!
    $data: ClinicPetParentUpdateInput!
  ) {
    clinicPetParent: updateOneClinicPetParent(where: $where, data: $data) {
      id
      isActive
      isDeleted
      creationSource
      firstName
      lastName
      email
      phoneNumbers {
        number
      }
    }
  }
`;

const PET_PARENT_IMPERSONATION = gql`
  mutation ($data: GenerateTinyUrlAndTokenInput!) {
    generateTinyUrlAndToken(data: $data)
  }
`;

const REDACT_PET_PARENT = gql`
  mutation redactPetParent($clinicPetParentId: String!) {
    setClinicPetParentPrivacy: setClinicPetParentPrivacy(
      clinicPetParentId: $clinicPetParentId
    ) {
      success
      confirmation
    }
  }
`;

function useClinicPetParentsLazyQuery() {
  return useLazyQuery(CLINIC_PET_PARENTS, {
    fetchPolicy: "network-only",
  });
}
export {
  useClinicPetParentsLazyQuery,
  PET_PARENT_IMPERSONATION,
  UPDATE_CLINIC_PET_PARENT,
  REDACT_PET_PARENT,
};
