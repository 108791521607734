import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const ORGANIZATIONS = gql`
  query organizations(
    $where: OrganizationWhereInput
    $orderBy: [OrganizationOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
    findManyOrganization: findManyOrganization(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      name
      clinics {
        id
        name
        firebaseId
      }
    }
    findManyOrganizationCount: findManyOrganizationCount(where: $where)
  }
`;
const ORGANIZATION_FINACIALDATA = gql`
  query ($where: OrganizationWhereUniqueInput!) {
    findManyOrganizationFinancialReportData(where: $where)
  }
`;
function useOrganizationsQuery() {
  const [loadOrganizations, { called, loading, data }] = useLazyQuery(
    ORGANIZATIONS,
    {
      fetchPolicy: "network-only",
    }
  );

  return [loadOrganizations, { called, loading, data }];
}
function useOrganizationFinancialDataQuery() {
  const [loadOrganizationFinancialData, { called, loading, data }] =
    useLazyQuery(ORGANIZATION_FINACIALDATA, {
      fetchPolicy: "network-only",
    });

  return [loadOrganizationFinancialData, { called, loading, data }];
}
export { useOrganizationsQuery, useOrganizationFinancialDataQuery };
