import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

function useGenericListQuery({ query, entity, fragment }) {
  const QUERY = gql`
    query ${query}($where: ${entity}WhereInput, $orderBy: ${entity}OrderByWithRelationInput) {
      ${query}(where: $where, orderBy: $orderBy) ${fragment}    }
  `;
  return useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
  });
}
function useGenericQuery({ query, entity, fragment }) {
  const QUERY = gql`
      query ${query}($where: ${entity}WhereUniqueInput) {        
        ${query}(where: $where) ${fragment}    
        }
    `;
  return useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
  });
}
export { useGenericListQuery, useGenericQuery };
