// // This is primarily (and, at the moment, exclusively) used for the "Default Provider" Functionality.

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const CLINIC_EMPLOYEES = gql`
  query getClinicEmployees(
    $where: ClinicEmployeeWhereInput!
    $skip: Int
    $take: Int
    $orderBy: [ClinicEmployeeOrderByWithRelationInput!]
  ) {
    clinicEmployees: findManyClinicEmployee(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      firstName
      lastName
      isActive
      isDeleted
      isVisible
      type
      clinic {
        id
        name
      }
      isDefaultExporter
    }
    clinicEmployeesCount: findManyClinicEmployeeCount(where: $where)
  }
`;

const TOGGLE_DEFAULT_EXPORTER = gql`
  mutation toggleDefaultExporter(
    $whereOne: ClinicEmployeeWhereUniqueInput!
    $whereAll: ClinicEmployeeWhereInput!
    $data: ClinicEmployeeUpdateInput!
  ) {
    toggleOffOtherDefaultExportersInClinic: updateManyClinicEmployee(
      where: $whereAll
      data: { isDefaultExporter: false }
    ) {
      count
    }

    newDefaultExporter: updateOneClinicEmployee(where: $whereOne, data: $data) {
      id
      firstName
      lastName
      clinicId
      isDefaultExporter
    }
  }
`;

const useClinicEmployeeLazyQuery = () =>
  useLazyQuery(CLINIC_EMPLOYEES, { fetchPolicy: "network-only" });

export { useClinicEmployeeLazyQuery, TOGGLE_DEFAULT_EXPORTER };
