import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const ENROLLMENTS = gql`
  query carePlanEnrollment(
    $where: CarePlanEnrollmentWhereInput
    $orderBy: [CarePlanEnrollmentOrderByWithRelationInput!]
    $skip: Int
    $take: Int
  ) {
    findManyCarePlanEnrollment(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      status
      createdAt
      organizationPet {
        id
        preferredName
      }
      careStripeSubscription {
        subscriptionOwner {
          preferredLastName
          preferredFirstName
          stripeCustomerTableId
        }
        associatedCarePlanProviderGroup {
          id
          paymentProcessorClinic {
            name
          }
        }
      }
      plan {
        title
        pricePerRenewal
        renewalCycle
      }
    }
    findManyCarePlanEnrollmentCount(where: $where)
  }
`;
let defaultVariables = {
  where: {
    status: {
      in: ["Active"],
    },
    careStripeSubscription: {
      associatedCarePlanProviderGroup: {
        id: {
          not: {
            // exclude Televet Test Clinic and Cornerstone Test Clinic associated care plan provider groups
            in: ["clfvmj2fy0023jqwsrhtlfayl", "clfvmnk8b0023rtwswiip6of7"],
          },
        },
      },
    },
  },
  orderBy: { createdAt: "desc" }, // Newest first
};

// Only add 'isTestSubscription' field for prod and demo
if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "demo"
) {
  defaultVariables.where.careStripeSubscription.isTestSubscription = {
    equals: false,
  };
}

function useEnrollmentsQuery(variables = {}) {
  const mergedVariables = { ...defaultVariables, ...variables };

  const [loadEnrollments, { called, loading, data }] = useLazyQuery(
    ENROLLMENTS,
    {
      fetchPolicy: "network-only",
      variables: mergedVariables,
    }
  );
  const total = data?.findManyCarePlanEnrollmentCount;
  // Process the received data and calculate the monthly cost
  const processedData = data?.findManyCarePlanEnrollment?.map((item, index) => {
    const monthly_cost =
      item.plan.renewalCycle === "Monthly"
        ? item.plan.pricePerRenewal * 0.01
        : item.plan.renewalCycle === "Annually"
        ? (item.plan.pricePerRenewal / 12.0) * 0.01
        : null;

    return {
      ...item,
      monthly_cost,
      number: index + 1,
      numberDesc: total - index,
    };
  });

  return [
    (variables) =>
      loadEnrollments({ variables: { ...mergedVariables, ...variables } }),
    {
      called,
      loading,
      data: { ...data, findManyCarePlanEnrollment: processedData, total },
    },
  ];
}

export { useEnrollmentsQuery };
