import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
const CHECK_PHONE_VERIFICATION = gql`
  query checkPhoneVerification($data: CheckPhoneVerificationInput!) {
    checkPhoneVerification(data: $data)
  }
`;
const USER_IMPERSONATION = gql`
  mutation createImpersonation($data: IdInput!) {
    createImpersonation(data: $data) {
      token
    }
  }
`;
const USERS = gql`
  query users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $staffRoleWhere: StaffRoleWhereInput
  ) {
    findManyUser: findManyUser(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      firstName
      lastName
      email
      phone
      type
      isActive
      clinics {
        id
        name
        firebaseId
      }
      ownerInfo {
        pets {
          name
          breed
          gender
          bornOn
          firebaseId
        }
      }
      vetInfo {
        roles(where: $staffRoleWhere) {
          id
          role
        }
        currentClinic {
          id
        }
      }

      firebaseId
    }
    findManyUserCount: findManyUserCount(where: $where)
  }
`;
function useUsersQuery() {
  const [loadUsers, { called, loading, data }] = useLazyQuery(USERS, {
    fetchPolicy: "network-only",
  });

  return [loadUsers, { called, loading, data }];
}
function useCheckPhoneVerification() {
  const [load, { called, loading, data }] = useLazyQuery(
    CHECK_PHONE_VERIFICATION,
    {
      fetchPolicy: "network-only",
    }
  );

  return [load, { called, loading, data }];
}
export { useUsersQuery, useCheckPhoneVerification, USER_IMPERSONATION };
